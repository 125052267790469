import React from "react";
import PropTypes from "prop-types";

import WithThemeLoading from "../hoc/withThemeLoading";
import theme1PropertiesMap from "../themes/theme1/theme1PropertiesMap";
import Theme2PropertiesMap from "../themes/theme2/theme2PropertiesMap";
import Theme3PropertiesMap from "../themes/theme3/theme3PropertiesMap";
import Theme4PropertiesMap from "../themes/theme4/theme4PropertiesMap";
import Theme5PropertiesMap from "../themes/theme5/theme5PropertiesMap";

const PropertiesMap = (props) => {
  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? props.pageContext.template.id
      : process.env.GATSBY_TEST_THEME;
  const themes = [
    theme1PropertiesMap,
    Theme2PropertiesMap,
    Theme3PropertiesMap,
    Theme4PropertiesMap,
    Theme5PropertiesMap,
  ];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(theme1PropertiesMap);
  /** End: Theme Switcher */

  let allProperties = [];
  const context = require.context("../data/properties", false, /\.json$/);
  context.keys().forEach(key => {
    const fileName = key.replace('./', '');
    const records = require(`../data/properties/${fileName}`);
    allProperties = [...allProperties, ...records];    
  });

  return <ThemeLoading {...props} allProperties={allProperties} />;
};

PropertiesMap.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default PropertiesMap;